.wrapper {
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-top: none;
  border-bottom: none;
  background-color: rgb(238, 238, 238);
  padding: 4px;
  font-size: 12px;
}

.wrapper:last-child {
  border-bottom: 1px solid black;
}
